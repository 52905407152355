<template>
  <div>
    <v-dialog v-model="roleEditor" max-width="300px">
      <v-card>
        <v-card-title>{{ $t('editMember') }}</v-card-title>
        <v-card-text v-if="memberToEdit">
          <p>{{ $t('editMemberInfo') }}</p>
          <p>{{ $t('editMemberMsg', null, { user: memberToEdit.name }) }}</p>

          <v-text-field
            v-model="memberName"
            :label="$t('name')"
            :disabled="managed"
            :rules="memberNameRules"
            :counter="50"
          />

          <role-selector v-model="roleToAssignToMember" :label="$t('role')" />
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1" />
          <v-btn @click="cancelEditMemberNameAndRole">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" @click="updateMemberNameAndRole">
            {{ $t('update') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="d-flex">
      <h1 class="display-1 ma-0">{{ $t('members') }}</h1>
      <v-spacer />
      <v-text-field v-model="userSearch" :label="$t('search')" append-icon="fa-search" />
    </div>

    <v-data-table
      v-model="selectedMembers"
      :headers="computedHeaders"
      :items="members"
      :loading="loading"
      :loading-text="$t('loading')"
      :footer-props="footerProps"
      :show-expand="showExpand"
      :single-expand="true"
      show-select
      :search="userSearch"
      :custom-filter="search"
      :items-per-page="50"
      class="table"
    >
      <template #top>
        <v-expand-transition>
          <v-toolbar
            v-show="selectedMembers.length > 0"
            flat
            dark
            color="blueDark"
            :extended="true"
            height="auto"
            extension-height="auto"
            class="batch-edit-toolbar"
          >
            <v-toolbar-title>{{ $t('editRows') }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items class="my-n1" style="height: 56px">
              <v-btn text :disabled="isBatchRestoreDisabled" @click="promptRestoreSelectedMembers"
                ><v-icon left>fa-undo</v-icon> {{ $t('restore') }}
              </v-btn>
              <v-btn text :disabled="isBatchDeleteDisabled" @click="promptRemoveSelectedMembers"
                ><v-icon left>fa-trash</v-icon>{{ $t('remove') }}</v-btn
              >
              <v-btn text @click="cancelBatchEdit">{{ $t('cancel') }}</v-btn>
              <v-btn text :disabled="isBatchUpdateDisabled" @click="updateBatchEdit">{{
                $t('update')
              }}</v-btn>
            </v-toolbar-items>

            <template #extension>
              <v-toolbar-items style="width: 100%">
                <v-container class="pa-0">
                  <v-row align="start">
                    <v-col cols="12" md="6" lg="4" xl="2">
                      <role-selector
                        v-model="batchEditRole"
                        :label="$t('role')"
                        prepend-icon="fa-user-shield"
                      />
                    </v-col>

                    <!-- <v-col cols="12" md="6" lg="8" xl="10">
                      <team-selector v-model="table.batchEditTeams" :disabled="selectedMembers.length < 1" :rules="teamRules" :teams="teams" :label="$t('teams')" prepend-icon="fa-users" />
                    </v-col> -->
                  </v-row>
                </v-container>
              </v-toolbar-items>
            </template>
          </v-toolbar>
        </v-expand-transition>
      </template>
      <template #[`header.data-table-select`]="{ props, on }">
        <v-btn v-if="props.value" :key="'members' + 'deselect'" icon @click="on.input(false)"
          ><v-icon>fa-check-square</v-icon></v-btn
        >
        <v-btn v-else :key="'members' + 'select'" icon @click="on.input(true)"
          ><v-icon>far fa-square</v-icon></v-btn
        >
      </template>

      <template #[`item.data-table-select`]="{ item, select, isSelected }">
        <v-btn v-if="isSelected" :key="item.generatedId + 'deselect'" icon @click="select(false)"
          ><v-icon>fa-check-square</v-icon></v-btn
        >
        <v-btn v-else :key="item.generatedId + 'select'" icon @click="select(true)"
          ><v-icon>far fa-square</v-icon></v-btn
        >
      </template>

      <template #[`item.profilePhoto`]="{ item }">
        <profile-photo
          :class="{ deleted: item.isSoftDeleted }"
          :url="item.profilePhoto"
          :user-name="item.name"
        />
      </template>

      <template #[`item.name`]="{ item }">
        <span :class="{ deleted: item.isSoftDeleted }"> {{ item.name }} </span>
      </template>

      <template #[`item.email`]="{ item }">
        <span :class="{ deleted: item.isSoftDeleted }"> {{ item.email }} </span>
      </template>

      <template #[`item.deviceCount`]="{ item }">
        <v-chip
          :class="{ deleted: item.isSoftDeleted }"
          :color="getCountColor(item.deviceCount)"
          outlined
        >
          <v-avatar left>
            <v-icon class="pa-1">fa-mobile</v-icon>
          </v-avatar>
          {{ item.deviceCount }}
        </v-chip>
      </template>

      <template #[`item.mobile`]="{ item }">
        <span :class="{ deleted: item.isSoftDeleted }"> {{ item.mobile }} </span>
      </template>

      <template #[`item.role`]="{ item }">
        <div class="d-flex align-end" :class="{ deleted: item.isSoftDeleted }">
          <v-icon class="pa-1">fa-{{ getRoleIcon(item.role) }}</v-icon>
          {{ $t('role_' + item.role) }}
        </div>
      </template>

      <template #[`item.managed`]="{ item }">
        <template v-if="item.managed">
          <v-icon :title="$t('managedUserInfo')" class="pa-1">fa-fw fa-id-badge</v-icon>
          {{ $t('managed') }}
        </template>
      </template>

      <template #[`item.actions`]="{ item }">
        <template v-if="showActionText">
          <template v-if="item.isSoftDeleted">
            <v-btn @click="promptRestoreMember(item)">
              <v-icon left>fa-undo</v-icon>
              {{ $t('restore') }}
            </v-btn>
          </template>
          <template v-else>
            <v-btn @click="editMember(item)">
              <v-icon left>fa-pen</v-icon>
              {{ $t('edit') }}
            </v-btn>
            <v-btn :disabled="item.managed" @click="promptRemoveMember(item)">
              <v-icon left>fa-trash</v-icon>
              {{ $t('remove') }}
            </v-btn>
          </template>
        </template>
        <template v-else>
          <template v-if="item.isSoftDeleted">
            <v-btn icon @click="promptRestoreMember(item)">
              <v-icon class="pa-1">fa-undo</v-icon>
            </v-btn>
          </template>
          <template v-else>
            <v-btn icon @click="editMember(item)">
              <v-icon class="pa-1">fa-pen</v-icon>
            </v-btn>
            <v-btn :disabled="item.managed" icon @click="promptRemoveMember(item)">
              <v-icon class="pa-1">fa-trash</v-icon>
            </v-btn>
          </template>
        </template>
      </template>

      <template #[`item.data-table-expand`]="data">
        <v-btn :key="data.item.id + data.isExpanded" icon @click="data.expand(!data.isExpanded)">
          <v-icon v-if="data.isExpanded">fa-angle-up</v-icon>
          <v-icon v-else>fa-angle-down</v-icon>
        </v-btn>
      </template>

      <template v-if="showExpand" #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container class="pa-0">
            <v-row align="center">
              <v-col class="text-center" :class="{ deleted: item.isSoftDeleted }">
                <label class="font-weight-bold">{{ $t('email') }}:</label>
                {{ item.email }}
              </v-col>

              <v-col class="text-center" :class="{ deleted: item.isSoftDeleted }">
                <label class="font-weight-bold">{{ $t('phone') }}:</label>
                {{ item.mobile }}
              </v-col>

              <v-col class="text-center" :class="{ deleted: item.isSoftDeleted }">
                <label class="font-weight-bold">{{ $t('devices') }}:</label>
                <v-chip :color="getCountColor(item.deviceCount)" outlined>
                  <v-avatar left>
                    <v-icon class="pa-1">fa-mobile</v-icon>
                  </v-avatar>
                  {{ item.deviceCount }}
                </v-chip>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ProfilePhoto from '@/components/ProfilePhoto.vue';
import { gray600, redPrimary } from '@/lib/ui/colors';
import api from '@/util/api';
import errorMessage from '@/util/errorMessage';
import confirmDialog from '@/components/dialog/confirmDialog';
import RoleSelector from '@/components/RoleSelector.vue';
import { isRequired, maximum, minimum } from '@/consts/inputRules';

const memberNameRules = [
  (name) => isRequired('name', name),
  (name) => minimum('name', name, 3),
  (name) => maximum('name', name, 50),
];

export default {
  name: 'MembersTable',

  components: {
    ProfilePhoto,
    RoleSelector,
  },

  props: {
    members: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  data: (self) => ({
    roleEditor: null,
    memberToEdit: null,
    managed: false,
    memberName: '',
    memberNameRules,
    roleToAssignToMember: null,
    roles: [
      {
        value: 'user',
        text: self.$t('role_user'),
      },
      {
        value: 'admin',
        text: self.$t('role_admin'),
      },
    ],

    selectedMembers: [],
    batchEditRole: 'user',

    userSearch: '',

    headers: [
      {
        value: 'profilePhoto',
        text: self.$t('photo'),
      },
      {
        value: 'name',
        sortable: true,
      },
      {
        value: 'email',
        hide: true,
        sortable: true,
      },
      {
        value: 'deviceCount',
        text: self.$t('devices'),
        hide: true,
        sortable: true,
      },
      {
        value: 'mobile',
        text: self.$t('phone'),
        hide: true,
        sortable: true,
      },
      {
        value: 'role',
        sortable: true,
      },
      {
        value: 'managed',
        align: 'center',
      },
      {
        value: 'actions',
        align: 'end',
        text: self.$t('actions'),
      },
    ].map((header) => ({
      ...header,
      text: header.text || self.$t(header.value),
      sortable: header.sortable === true ? true : false,
    })),

    footerProps: {
      itemsPerPageOptions: [10, 50, 100],
    },
  }),

  computed: {
    isBatchUpdateDisabled() {
      return !!this.selectedMembers.find((m) => m.isSoftDeleted);
    },

    isBatchDeleteDisabled() {
      return !!this.selectedMembers.find((m) => m.isSoftDeleted || m.managed);
    },

    isBatchRestoreDisabled() {
      return !!this.selectedMembers.find((m) => !m.isSoftDeleted);
    },

    hasManagedMembers() {
      return this.members.find((m) => m.managed);
    },

    computedHeaders() {
      return this.headers.filter((h) => {
        let show = true;
        if (h.value === 'managed') {
          show = show && this.hasManagedMembers;
        }

        if (h.hide) {
          show = show && !this.showExpand;
        }

        return show;
      });
    },

    showExpand() {
      return this.$vuetify.breakpoint.lgAndDown && this.$vuetify.breakpoint.width >= 600;
    },

    showActionText() {
      return this.$vuetify.breakpoint.width < 600;
    },
  },

  watch: {
    members(newMembers) {
      // When updating members we must also update the selectedMembers instances,
      // which are used by computed properties.
      const updatedSelectedMembers = [];

      for (const sm of this.selectedMembers) {
        const newMember = newMembers.find((nm) => nm.id == sm.id);
        if (newMember) {
          updatedSelectedMembers.push(newMember);
        }
      }

      this.selectedMembers = updatedSelectedMembers;
    },
  },

  methods: {
    getCountColor: (count) => {
      if (count < 1) {
        return redPrimary;
      } else {
        return gray600;
      }
    },

    getRoleIcon: (role) =>
      ({
        user: 'user',
        admin: 'user-crown',
        superAdmin: 'user-cowboy',
      }[role] || 'user'),

    editMember(member) {
      this.memberToEdit = member;
      this.managed = member.managed;
      this.memberName = member.name;
      this.roleToAssignToMember = member.role;
      this.roleEditor = true;
    },

    updateMemberNameAndRole() {
      this.roleEditor = false;
      api
        .updateUserNameAndRole({
          userId: this.memberToEdit.userId,
          name: this.memberName,
          role: this.roleToAssignToMember,
        })
        .then(() => {
          this.$emit('memberUpdated');
          this.memberToEdit.role = this.roleToAssignToMember;
        })
        .catch((res) =>
          errorMessage(
            this.$t('updateUserNameAndRoleError'),
            this.$t('updateUserNameAndRoleErrorMsg'),
            res,
          ),
        );
    },

    cancelEditMemberNameAndRole() {
      this.roleEditor = false;
      this.memberToEdit = null;
      this.memberName = '';
      this.roleToAssignToMember = null;
    },

    promptRemoveMember(member) {
      confirmDialog(
        this.$t('removeMember'),
        this.$t('removeMemberMsg', null, {
          userEmail: member.email,
        }),
        this.$t('cancel'),
        this.$t('remove'),
      )
        .open()
        .then((confirm) => {
          if (confirm) {
            api
              .deleteUser(member.userId)
              .then(() => {
                this.$emit('memberRemoved');
              })
              .catch((res) => {
                errorMessage(this.$t('deleteUserError'), this.$t('deleteUserErrorMsg'), res);
              });
          }
        });
    },

    promptRemoveSelectedMembers() {
      confirmDialog(
        this.$t('removeMembers'),
        this.$t('removeMembersMsg', null, {
          count: this.selectedMembers.length,
        }),
        this.$t('cancel'),
        this.$t('remove'),
      )
        .open()
        .then((confirm) => {
          if (confirm) {
            const tasks = [];
            this.selectedMembers.forEach((member) => {
              tasks.push(api.deleteUser(member.userId));
            });
            Promise.all(tasks)
              .then(() => {
                this.$emit('memberRemoved');
                this.cancelBatchEdit();
              })
              .catch((res) => {
                errorMessage(this.$t('deleteUserError'), this.$t('deleteUserErrorMsg'), res);
              });
          }
        });
    },

    promptRestoreMember(member) {
      confirmDialog(
        this.$t('restoreMember'),
        this.$t('restoreMemberMsg', null, {
          userEmail: member.email,
        }),
        this.$t('cancel'),
        this.$t('restore'),
      )
        .open()
        .then((confirm) => {
          if (confirm) {
            api
              .restoreUser(member.userId)
              .then(() => {
                this.$emit('memberRestored');
              })
              .catch((res) => {
                errorMessage(this.$t('restoreUserError'), this.$t('restoreUserErrorMsg'), res);
              });
          }
        });
    },

    promptRestoreSelectedMembers() {
      confirmDialog(
        this.$t('restoreMembers'),
        this.$t('restoreMembersMsg', null, {
          count: this.selectedMembers.length,
        }),
        this.$t('cancel'),
        this.$t('restore'),
      )
        .open()
        .then((confirm) => {
          if (confirm) {
            const tasks = [];
            this.selectedMembers.forEach((member) => {
              tasks.push(api.restoreUser(member.userId));
            });
            Promise.all(tasks)
              .then(() => {
                this.$emit('memberRestored');
                this.cancelBatchEdit();
              })
              .catch((res) => {
                errorMessage(this.$t('restoreUserError'), this.$t('restoreUserErrorMsg'), res);
              });
          }
        });
    },

    cancelBatchEdit() {
      this.batchEditRole = 'user';
      this.selectedMembers = [];
    },

    updateBatchEdit() {
      const tasks = [];
      this.selectedMembers.forEach((member) => {
        tasks.push(
          api.updateUserNameAndRole({
            userId: member.userId,
            role: this.batchEditRole,
          }),
        );
      });

      Promise.all(tasks)
        .then(() => {
          this.$emit('memberUpdated');
          this.cancelBatchEdit();
        })
        .catch((res) =>
          errorMessage(this.$t('updateUserRoleError'), this.$t('updateUserRoleErrorMsg'), res),
        );
    },

    search: (value, search, item) => {
      const name = item.name.toLowerCase();
      const email = item.email.toLowerCase();
      const mobile = item.mobile;
      const role = item.role;

      search = search.toLowerCase();

      return (
        name.indexOf(search) >= 0 ||
        email.indexOf(search) >= 0 ||
        mobile.indexOf(search) >= 0 ||
        role.indexOf(search) >= 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table {
  background: transparent;
}
</style>

<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="slide-y-transition">
    <template #activator="data">
      <slot :on="data.on" />
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="cancel">
          <v-icon>fa-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('importUsers') }}</v-toolbar-title>
      </v-toolbar>

      <v-container>
        <v-row>
          <v-col cols="6" sm="4" md="3" lg="2">
            <v-btn
              :loading="loading"
              block
              to="/static/misc/batch-add-members-template.csv"
              target="_blank"
              tag="a"
              download="batch-add-members-template.csv"
              ><v-icon left>fa-file-download</v-icon>{{ $t('template') }}</v-btn
            >
          </v-col>

          <v-col cols="6" sm="4" md="3" lg="2">
            <v-btn :loading="loading" block color="primary" @click="uploadCsv"
              ><v-icon left>fa-file-upload</v-icon>{{ $t('uploadFile') }}</v-btn
            >
          </v-col>

          <v-col cols="12" sm="4" md="3" offset-md="3" lg="2" offset-lg="6">
            <v-btn
              :loading="loading"
              block
              color="primary"
              :disabled="parsedUsers.length < 1"
              @click="validateImport"
              ><v-icon left>fa-tasks</v-icon>{{ $t('revalidate') }}</v-btn
            >
          </v-col>
        </v-row>
        <v-spacer />
      </v-container>

      <v-container>
        <v-progress-linear :active="loading" indeterminate />
      </v-container>

      <v-container class="pa-0 pa-lg-3">
        <v-form ref="form">
          <v-expansion-panels v-model="panel">
            <v-expansion-panel
              v-for="(table, index) in tables"
              :key="index"
              :disabled="loading || table.items.length < 1"
              @change="$refs.form.validate()"
            >
              <v-expansion-panel-header>
                <p :class="table.items.length > 0 ? table.colorIfValues + '--text' : ''">
                  {{ table.name }} ({{ table.items.length }})
                </p>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <p class="body-2 font-weight-bold">{{ table.description }}</p>

                <v-data-table
                  v-model="table.selected"
                  :headers="computedHeaders"
                  :items="table.items"
                  :mobile-breakpoint="1100"
                  :footer-props="footerProps"
                  item-key="generatedId"
                  disable-sort
                  show-select
                  :show-expand="showExpand"
                  :single-expand="false"
                  :expanded.sync="table.expanded"
                  class="import-batch-table mx-n6"
                >
                  <template #top>
                    <v-expand-transition>
                      <v-toolbar
                        v-show="table.selected.length > 0"
                        flat
                        dark
                        color="blueDark"
                        :extended="true"
                        height="auto"
                        extension-height="auto"
                      >
                        <v-toolbar-title>{{ $t('editRows') }}</v-toolbar-title>
                        <v-spacer />
                        <v-toolbar-items class="my-n1" style="height: 56px">
                          <v-btn
                            text
                            :disabled="table.selected.length < 1"
                            @click="discardImports(table)"
                            ><v-icon left>fa-trash</v-icon>{{ $t('discard') }}</v-btn
                          >
                          <v-btn
                            text
                            :disabled="table.selected.length < 1"
                            @click="cancelBatchEdit(table)"
                            >{{ $t('cancel') }}</v-btn
                          >
                          <v-btn
                            text
                            :disabled="table.selected.length < 1"
                            @click="updateBatchEdit(table)"
                            >{{ $t('update') }}</v-btn
                          >
                        </v-toolbar-items>

                        <template #extension>
                          <v-toolbar-items style="width: 100%">
                            <v-container class="pa-0">
                              <v-row align="start">
                                <v-col cols="12" md="6" lg="4" xl="2">
                                  <role-selector
                                    v-model="table.batchEditRole"
                                    :disabled="table.selected.length < 1"
                                    :label="$t('role')"
                                    prepend-icon="fa-user-shield"
                                  />
                                </v-col>

                                <v-col cols="12" md="6" lg="8" xl="10">
                                  <team-selector
                                    v-model="table.batchEditTeams"
                                    :disabled="table.selected.length < 1"
                                    :team-disabled="(team) => team.managed"
                                    :rules="teamRules"
                                    :teams="teams"
                                    :label="$t('teams')"
                                    prepend-icon="fa-users"
                                    :can-create-new-teams="true"
                                    @teams-loaded="($teams) => teamsLoaded($teams)"
                                  />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-toolbar-items>
                        </template>
                      </v-toolbar>
                    </v-expand-transition>
                  </template>

                  <template #[`header.data-table-select`]="{ props, on }">
                    <v-btn
                      v-if="props.value"
                      :key="table.name + 'deselect'"
                      icon
                      @click="on.input(false)"
                      ><v-icon>fa-check-square</v-icon></v-btn
                    >
                    <v-btn v-else :key="table.name + 'select'" icon @click="on.input(true)"
                      ><v-icon>far fa-square</v-icon></v-btn
                    >
                  </template>

                  <template #[`item.data-table-select`]="{ item, select, isSelected }">
                    <v-btn
                      v-if="isSelected"
                      :key="item.generatedId + 'deselect'"
                      icon
                      @click="select(false)"
                      ><v-icon>fa-check-square</v-icon></v-btn
                    >
                    <v-btn v-else :key="item.generatedId + 'select'" icon @click="select(true)"
                      ><v-icon>far fa-square</v-icon></v-btn
                    >
                  </template>

                  <template #[`item.name`]="{ item }">
                    <v-text-field
                      v-model="item.name"
                      :label="showRowInputLabels ? $t('name') : null"
                      :rules="nameRules"
                      :counter="100"
                      :disabled="table.disableNameInput"
                      :messages="table.disableNameInput ? $t('userExistsInDbHasDifferentName') : []"
                      required
                    />
                  </template>

                  <template #[`item.email`]="{ item }">
                    <v-text-field
                      v-model="item.email"
                      :label="showRowInputLabels ? $t('email') : null"
                      :rules="emailRules"
                      :error-messages="getErrorMessages(item, 'email')"
                      :counter="100"
                      type="email"
                      required
                    />
                  </template>

                  <template #[`item.msisdn`]="{ item }">
                    <phone-field
                      v-model="item.msisdn"
                      :label="showRowInputLabels ? $t('phone') : null"
                      :rules="phoneRules"
                      :error-messages="getErrorMessages(item, 'msisdn')"
                      required
                    />
                  </template>

                  <template #[`item.role`]="{ item }">
                    <role-selector
                      v-model="item.role"
                      :label="showRowInputLabels ? $t('role') : null"
                    />
                  </template>

                  <template #[`item.teamIds`]="{ item }">
                    <team-selector
                      v-model="item.teamIds"
                      show-ids
                      :team-disabled="(team) => team.managed"
                      :rules="teamRules"
                      :error-messages="getErrorMessages(item, 'teamids')"
                      :label="showRowInputLabels ? $t('teams') : null"
                      :teams="teams"
                      :can-create-new-teams="true"
                      @teams-loaded="($teams) => teamsLoaded($teams)"
                    />
                  </template>

                  <template #[`item.discard`]="{ item }">
                    <v-btn
                      v-if="showRowInputLabels"
                      class="my-3"
                      @click="discardImport(item, table)"
                    >
                      <v-icon left>fa-trash fa-fw</v-icon>
                      {{ $t('discard') }}
                    </v-btn>
                    <v-btn v-else icon :title="$t('discard')" @click="discardImport(item, table)">
                      <v-icon class="pa-1">fa-trash fa-fw</v-icon>
                    </v-btn>
                  </template>

                  <template #[`item.data-table-expand`]="data">
                    <v-btn
                      :key="data.item.generatedId + data.isExpanded"
                      icon
                      @click="data.expand(!data.isExpanded)"
                    >
                      <v-icon v-if="data.isExpanded">fa-angle-up</v-icon>
                      <v-icon v-else>fa-angle-down</v-icon>
                    </v-btn>
                  </template>

                  <template v-if="showExpand" #expanded-item="{ item }">
                    <td :colspan="3" />
                    <td><role-selector v-model="item.role" :label="$t('role')" /></td>

                    <td>
                      <team-selector
                        v-model="item.teamIds"
                        show-ids
                        :team-disabled="(team) => team.managed"
                        :rules="teamRules"
                        :teams="teams"
                        :error-messages="getErrorMessages(item, 'teamids')"
                        :label="$t('teams')"
                        :can-create-new-teams="true"
                        @teams-loaded="($teams) => teamsLoaded($teams)"
                      />
                    </td>
                    <td class="text-end">
                      <v-btn
                        v-if="showRowInputLabels"
                        class="my-3"
                        @click="validateSingleUser(item, table)"
                      >
                        <v-icon left>fa-redo-alt fa-fw</v-icon>
                        {{ $t('revalidate') }}
                      </v-btn>
                      <v-btn
                        v-else
                        icon
                        :title="$t('revalidate')"
                        @click="validateSingleUser(item, table)"
                      >
                        <v-icon class="pa-1">fa-redo-alt fa-fw</v-icon>
                      </v-btn>
                    </td>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>

        <v-container v-if="usersExistsCompany.length > 0" class="d-flex justify-center">
          <p>
            {{
              $t('importedUsersAlreadyCompanyMembers', null, {
                userCount: usersExistsCompany.length,
              })
            }}
          </p>
        </v-container>

        <v-container class="d-flex justify-end">
          <p class="ma-0 my-2 mr-2">
            {{ $t('seatsInUse') }}: {{ members.length }}/{{ maxMembers + extraMembers }}
          </p>
          <v-spacer />
          <p
            v-if="parsedUsers.length > 0"
            class="ma-0 my-2 mr-2"
            :class="hasEnoughSeatsForImportedMembers ? 'blueDark--text' : 'redDark--text'"
          >
            {{ $t('seatsLeftAfterImport') }}: {{ seatsLeftAfterImport }}
          </p>
          <v-spacer />
          <v-btn class="mr-2" :loading="loading" @click="cancel">{{ $t('cancel') }}</v-btn>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="usersError.length > 0 || usersToAddCount < 1"
            @click="add"
            >{{ $t('import') }}</v-btn
          >
        </v-container>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import fileInput from '@/util/fileInput';
import { translate as t } from '@/util/i18n';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import PhoneField from '@/components/PhoneField.vue';
import api from '@/util/api';
import { v4 as uuid } from 'uuid';
import { nameRules, emailRules, phoneRules, teamRules } from '@/consts/userRules';
import formatToCSV from './formatToCsv';
import confirmDialog from '@/components/dialog/confirmDialog';
import errorMessage from '@/util/errorMessage';
import genericErrorMessage from '@/util/genericErrorMessage';
import TeamSelector from '@/components/TeamSelector.vue';
import RoleSelector from '@/components/RoleSelector.vue';
import is from '@/lib/util/is';
import safeGet from '@/lib/util/safeGet';
import { readToString } from '@/util/fileHelper';

export default {
  name: 'ImportBatch',

  components: {
    PhoneField,
    TeamSelector,
    RoleSelector,
  },

  props: {
    getMemberByEmail: {
      type: Function,
      required: false,
      default: () => null,
    },

    getMemberByPhone: {
      type: Function,
      required: false,
      default: () => null,
    },

    teams: {
      type: Array,
      required: true,
    },

    members: {
      type: Array,
      required: false,
      default: () => [],
    },

    maxMembers: {
      type: Number,
      required: false,
      default: 0,
    },

    extraMembers: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  data: (self) => ({
    dialog: false,
    loading: false,
    panel: [],
    valid: false,

    headers: [
      {
        value: 'name',
        sortable: true,
      },
      {
        value: 'email',
        sortable: true,
      },
      {
        value: 'msisdn',
        text: t('phone'),
        sortable: true,
      },
      {
        value: 'role',
        sortable: true,
        hide: true,
      },
      {
        value: 'teamIds',
        text: t('teams'),
        hide: true,
      },
      {
        value: 'discard',
        align: 'end',
      },
    ].map((header) => ({
      ...header,
      class: `table-header table-header-${header.value}`,
      text: header.text || t(header.value),
      sortable: header.sortable === true ? true : false,
    })),

    footerProps: {
      itemsPerPageOptions: [10, 25, 50],
    },

    parsedUsers: [],
    usersError: [],
    usersExistsCompany: [],
    usersExistsDb: [],
    usersOk: [],

    usersErrorSelected: [],
    usersExistsDbSelected: [],
    usersOkSelected: [],

    nameRules: nameRules(),
    emailRules: emailRules(self.getMemberByEmail),
    phoneRules: phoneRules(self.getMemberByPhone),
    teamRules: teamRules(),
  }),

  computed: {
    tables() {
      return [
        {
          name: t('errors'),
          description: t('importErrorsDescription'),
          colorIfValues: 'redDark',
          icon: 'fa-exclamation-circle',
          selected: this.usersErrorSelected,
          items: this.usersError,
          expanded: this.usersError,
        },
        {
          name: t('existsInDb'),
          description: t('importExistsInDbDescription'),
          colorIfValues: 'blueDark',
          icon: 'fa-check',
          disableNameInput: true,
          selected: this.usersExistsDbSelected,
          items: this.usersExistsDb,
        },
        {
          name: t('ok'),
          description: t('importOkDescription'),
          colorIfValues: 'blueDark',
          icon: 'fa-check',
          selected: this.usersOkSelected,
          items: this.usersOk,
        },
      ].map((table) => ({
        ...table,
        batchEditRole: 'user',
        batchEditTeams: [],
        expanded: table.expanded || [],
      }));
    },

    computedHeaders() {
      return this.headers.filter(
        (h) =>
          !h.hide || !this.$vuetify.breakpoint.lgAndDown || this.$vuetify.breakpoint.width < 1100,
      );
    },

    showRowInputLabels() {
      return this.$vuetify.breakpoint.width < 1100;
    },

    showExpand() {
      return this.$vuetify.breakpoint.lgAndDown && this.$vuetify.breakpoint.width >= 1100;
    },

    usersToAddCount() {
      return this.usersOk.length + this.usersExistsDb.length;
    },

    seatsLeftAfterImport() {
      return this.maxMembers + this.extraMembers - this.members.length - this.usersToAddCount;
    },

    hasEnoughSeatsForImportedMembers() {
      return this.seatsLeftAfterImport >= 0;
    },
  },

  methods: {
    open() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    parsePhone: (msisdn) => {
      const data = parsePhoneNumberFromString(msisdn);
      const parsed = Boolean(data);
      return {
        extension: parsed ? data.countryCallingCode : '',
        number: parsed ? data.nationalNumber : '',
        complete: parsed ? data.number : '',
      };
    },

    reset() {
      this.parsedUsers = [];
      this.usersError = [];
      this.usersExistsDb = [];
      this.usersOk = [];
      this.valid = false;
    },

    cancel() {
      if (this.parsedUsers.length > 0) {
        confirmDialog(t('cancelBatchImport'), t('cancelBatchImportMsg'), t('no'), t('yes'))
          .open()
          .then((confirmed) => {
            if (confirmed) {
              this.reset();
              this.close();
            }
          });
      } else {
        this.reset();
        this.close();
      }
    },

    add() {
      confirmDialog(
        t('importUsers'),
        t('importDialogText', null, {
          newUsersCount: this.usersToAddCount,
        }),
        t('cancel'),
        t('import'),
      )
        .open()
        .then((confirmed) => {
          if (confirmed) {
            this.loading = true;
            api
              .addUsers(
                this.parsedUsers.map((user) => ({
                  ...user,
                  msisdn: user.msisdn.complete,
                })),
              )
              .then(() => {
                this.loading = false;
                this.$emit('membersAdded', this.parsedUsers);
                this.reset();
                this.close();
              })
              .catch((res) => {
                this.loading = false;
                errorMessage(t('importError'), t('importErrorMsg'), res);
              });
          }
        });
    },

    downloadTemplate: () => {
      window.location = '/static/misc/batch-add-members-template.csv';
    },

    validateImport() {
      this.loading = true;

      api
        .validateImport(
          this.parsedUsers.map((user) => ({
            ...user,
            msisdn: user.msisdn.complete,
            teamIds: user.teamIds,
          })),
        )
        .then((res) => {
          this.usersOk = res.ok;
          this.usersError = res.fault;
          this.usersExistsDb = res.existsInOtherCustomer;
          this.userExistsInCompany = res.existsInThisCustomer;
          this.parsedUsers = [
            ...res.ok,
            ...res.fault,
            ...res.existsInOtherCustomer,
            ...res.existsInThisCustomer,
          ];
          this.parsedUsers.forEach((user) => {
            user.msisdn = this.parsePhone(user.msisdn);
            user.role = user.role || 'user';
            user.generatedId = uuid();
          });
          this.usersError.forEach((user) => {
            user.isExpanded = true;
          });

          this.$refs.form.validate();
          this.valid = this.usersError.length < 1;
          this.loading = false;
        })
        .catch((res) => {
          this.loading = false;
          genericErrorMessage(res);
        });
    },

    validateSingleUser(user, table) {
      this.loading = true;

      api
        .validateImport([
          {
            ...user,
            msisdn: user.msisdn.complete,
            teamIds: user.teamIds.map((x) => x.teamId),
          },
        ])
        .then((res) => {
          const validationErrors = safeGet(res, 'fault', '0', 'validationErrors') || [];
          user.validationErrors = validationErrors;

          if (validationErrors.length < 1) {
            // Remove from old table
            const index = table.items.indexOf(user);
            if (index >= 0) {
              table.items.splice(index, 1);
            }

            if (res.ok.length > 0) {
              this.usersOk.push(user);
            } else if (res.existsInOtherCustomer.length > 0) {
              this.usersExistsDb.push(user);
            } else if (res.existsInThisCustomer.length > 0) {
              this.usersExistsCompany.push(user);
            } else {
              this.usersError.push(user);
            }
          }

          this.valid = this.usersError.length < 1;
          this.loading = false;
        })
        .catch((res) => {
          this.loading = false;
          genericErrorMessage(res);
        });
    },

    getErrorMessages(item, field) {
      const errorMessages = [];
      if (
        item.validationErrors.find(
          (error) => error.type === 3 && (field === 'msisdn' || field === 'email'),
        )
      ) {
        errorMessages.push(this.$t('addUserExistsErrorMsg'));
      }

      if (
        item.validationErrors.find(
          (error) =>
            (error.type === 1 || error.type === 2) &&
            error.field.toLowerCase() === field &&
            field === 'msisdn',
        )
      ) {
        errorMessages.push(this.$t('invalidPhoneNumber'));
      }

      if (
        item.validationErrors.find(
          (error) =>
            (error.type === 1 || error.type === 2) &&
            error.field.toLowerCase() === field &&
            field === 'email',
        )
      ) {
        errorMessages.push(this.$t('invalidEmail'));
      }

      if (
        item.validationErrors.find(
          (error) =>
            (error.type === 1 || error.type === 2) &&
            error.field.toLowerCase() === field &&
            field === 'teamids',
        )
      ) {
        errorMessages.push(this.$t('missingTeamIds'));
      }
      return errorMessages;
    },

    parseUsers(rows) {
      const users = rows
        .filter((row, index) => index > 0) // Remove header
        .map((row) => {
          const cells = row.split(';');
          let msisdn = cells[2];
          if (msisdn && msisdn.indexOf('+') < 0) {
            msisdn = '+' + msisdn;
          }

          const teamIds = [];
          const teamsCell = cells[4];
          if (teamsCell) {
            teamsCell.split('+').forEach((teamId) => {
              teamId = parseInt(teamId, 10);
              if (is.number(teamId)) {
                const team = this.teams.find((team) => team.id === teamId);
                if (team && !team.managed) {
                  teamIds.push(teamId);
                }
              }
            });
          }

          return {
            name: cells[0] || '',
            email: cells[1] || '',
            msisdn: this.parsePhone(msisdn),
            role: (cells[3] || '').toLowerCase(),
            teamIds,
          };
        });

      this.parsedUsers = users;
      this.validateImport();
    },

    uploadCsv() {
      fileInput('.csv,.tsv', false).then(async (file) => {
        this.loading = true;
        try {
          const text = await readToString(file);
          const contents = formatToCSV(text);
          const rows = contents.split('\n');
          const rowCount = rows.length - 1; // Ignore header
          if (rowCount > 500) {
            const confirmed = await confirmDialog(
              t('largeImport'),
              t('largeImportMsg', null, {
                max: 500,
                count: rowCount,
              }),
              t('cancel'),
              t('ok'),
            ).open();

            if (confirmed) {
              this.parseUsers(rows);
            }
          } else {
            this.parseUsers(rows);
          }
        } catch (error) {
          errorMessage(t('fileReadError'), t('uploadCsvFileError'), error);
          this.loading = false;
        }
      });
    },

    discardImports(table) {
      confirmDialog(
        t('discardImports'),
        t('discardImportsMsg', null, { importsCount: table.selected.length }),
        t('cancel'),
        t('discard'),
      )
        .open()
        .then((confirmed) => {
          if (confirmed) {
            this.parsedUsers = this.parsedUsers.filter(
              (user) =>
                !table.selected.find(
                  (selectedUser) => user.generatedId === selectedUser.generatedId,
                ),
            );

            table.selected.forEach((selectedUser) => {
              table.items.splice(table.items.indexOf(selectedUser), 1);
            });

            this.cancelBatchEdit(table);
          }
        });
    },

    discardImport(item, table) {
      confirmDialog(t('discardImport'), t('discardImportMsg'), t('cancel'), t('discard'))
        .open()
        .then((confirmed) => {
          if (confirmed) {
            this.parsedUsers = this.parsedUsers.filter(
              (user) => user.generatedId !== item.generatedId,
            );
            table.items.splice(table.items.indexOf(item), 1);
          }
        });
    },

    uuid: () => uuid(),

    cancelBatchEdit(table) {
      table.batchEditRole = 'user';
      table.batchEditTeams = [];
      this.usersErrorSelected = [];
      this.usersExistsDbSelected = [];
      this.usersOkSelected = [];
    },

    updateBatchEdit(table) {
      table.selected.forEach((user) => {
        user.role = table.batchEditRole;
        user.teamIds = table.batchEditTeams;
      });
      this.validateImport();
      this.cancelBatchEdit(table);
    },

    teamsLoaded(teams) {
      this.$emit('teams-loaded', teams);
    },
  },
};
</script>

<style lang="scss">
@media all and (max-width: 1099px) {
  .import-batch-table.v-data-table td {
    height: auto;
  }
}

.import-batch-table {
  .v-data-table__mobile-row__header {
    display: none;
  }

  .v-data-table__mobile-row__cell {
    width: 100%;
  }
}
</style>

<style lang="scss" scoped>
.import-batch-table ::v-deep .table-header {
  min-width: 200px;
}

.import-batch-table ::v-deep .table-header-teams {
  min-width: 300px;
}

.import-batch-table ::v-deep .table-header-discard {
  min-width: unset;
}
.import-batch-table ::v-deep .v-data-table__expanded__content {
  box-shadow: unset;
  td {
    padding-bottom: 8px;
  }
}
.import-batch-table ::v-deep .v-data-table__expanded__row td {
  border-bottom: 0 !important;
  padding-bottom: 8px;
}
</style>

<template>
  <v-select
    v-model="selectedRole"
    :label="label"
    :items="roles"
    :prepend-icon="prependIcon"
    :disabled="disabled"
    @change="onChange"
  >
    <template #item="{ item }">
      <v-list-item-icon>
        <v-icon small>fa-{{ getRoleIcon(item.value) }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        {{ item.text }}
      </v-list-item-content>
    </template>

    <template #selection="{ item }">
      <v-list-item-content>
        {{ item.text }}
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
import { translate as t } from '@/util/i18n';

const roles = [
  {
    value: 'user',
    text: t('role_user'),
  },
  {
    value: 'admin',
    text: t('role_admin'),
  },
];

export default {
  name: 'RoleSelector',

  props: {
    value: {
      type: String,
      required: false,
      default: 'user',
    },

    prependIcon: {
      type: String,
      required: false,
      default: null,
    },

    label: {
      type: String,
      required: false,
      default: null,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    roles,
    selectedRole: '',
  }),

  watch: {
    value() {
      this.selectedRole = this.value;
    },
  },

  created() {
    this.selectedRole = this.value;
  },

  methods: {
    onChange() {
      this.$emit('input', this.selectedRole);
    },

    getRoleIcon: (role) =>
      ({
        user: 'user',
        admin: 'user-crown',
      }[role] || 'user'),
  },
};
</script>

<style></style>

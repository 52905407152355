<template>
  <div>
    <div class="gray200">
      <v-container>
        <v-expansion-panels v-model="panel">
          <v-expansion-panel class="transparent">
            <v-expansion-panel-header>
              <div class="d-flex align-center ml-n5">
                {{ $t('addMember') }}
                <v-icon small class="ml-2">fa-user-plus</v-icon>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <div v-if="!canAddMoreMembers" class="mt-4">
                <v-alert type="error" icon="fa-exclamation-circle" border="left">
                  <p
                    class="white--text ma-0 cannot-add-more-members-message"
                    v-html="$t('cannotAddMoreMembersMsg', null, { mail: 'sales@teamalert.net' })"
                  />
                  <!-- If customer can upgrade -->
                  <!-- <v-btn
                    v-if="enterprise"
                    color="white"
                    class="mt-2"
                    @click="addExtraSeatsDialog = true"
                  >
                    <span class="error--text">
                      {{ $t('addExtraSeats') }}
                    </span>
                  </v-btn> -->
                </v-alert>
              </div>

              <import-batch
                :teams="teams"
                :members="membersWithoutSoftDeleted"
                :max-members="maxMembers"
                :extra-members="extraMembers"
                :get-member-by-email="getMemberByEmail"
                :get-member-by-phone="getMemberByPhone"
                @teams-loaded="($teams) => updateTeams($teams)"
                @membersAdded="loadCompany"
              >
                <template #default="{ on }">
                  <v-btn :disabled="!canAddMoreMembers" v-on="on">
                    <v-icon left>fa-file-upload</v-icon>
                    {{ $t('import') }}
                  </v-btn>
                </template>
              </import-batch>

              <v-spacer />

              <add-member
                :teams="teams"
                :members="membersWithoutSoftDeleted"
                :max-members="maxMembers"
                :extra-members="extraMembers"
                :get-member-by-email="getMemberByEmail"
                :get-member-by-phone="getMemberByPhone"
                @teams-loaded="($teams) => updateTeams($teams)"
                @cancel="cancelAddMember"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </div>

    <!-- If customer can upgrade -->
    <!-- <v-container v-if="enterprise">
      <p>{{ $t('seatsIncludedInPlan') }}: {{ maxMembers }}</p>
      <p>{{ $t('extraSeats') }}: {{ extraMembers }}</p>
      <p>{{ $t('seatsInUse') }}: {{ members.length }}</p>
      <v-btn @click="seatsToSet = members.length - maxMembers < 0 ? 0 : members.length - maxMembers; setExtraSeatsDialog = true">
        {{ $t('setExtraSeats') }}
      </v-btn>
    </v-container> -->

    <v-container class="mt-10">
      <members-table :members="members" :reload="loadMembers" :loading="loadingMembers" />
    </v-container>

    <!-- If customer can upgrade -->
    <!-- <v-dialog v-model="addExtraSeatsDialog" max-width="300px">
      <v-card>
        <v-card-title>{{ $t('addExtraSeats') }}</v-card-title>
        <v-card-text>
          <p>{{ $t('addExtraSeatsInfo', null, { price: 20 + ' NOK' }) }}</p>
          <v-text-field
            v-model="seatsToAdd"
            :label="$t('extraSeats')"
            type="number"
            autofocus
            min="1"
          />
          <p>{{ $t('addExtraSeatsSum', null, { price: (20 * seatsToAdd) + ' NOK' }) }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="cancelAddExtraSeats">{{ $t('cancel') }}</v-btn>
          <v-btn
            color="primary"
            :disabled="addingSeats || !seatsToAdd || seatsToAdd < 1"
            @click="addExtraSeats"
          >{{ $t('add') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!-- If customer can upgrade -->
    <!-- <v-dialog v-model="setExtraSeatsDialog" max-width="300px">
      <v-card>
        <v-card-title>{{ $t('setExtraSeats') }}</v-card-title>
        <v-card-text>
          <p>{{ $t('setExtraSeatsInfo', null, { price: 20 + ' NOK' }) }}</p>
          <v-text-field
            v-model="seatsToSet"
            :label="$t('extraSeats')"
            type="number"
            autofocus
            :min="members.length - maxMembers < 0 ? 0 : members.length - maxMembers"
          />
          <p>{{ $t('setExtraSeatsSum', null, { price: (20 * seatsToSet) + ' NOK' }) }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="cancelSetExtraSeats">{{ $t('cancel') }}</v-btn>
          <v-btn
            color="primary"
            :disabled="settingSeats || (!seatsToSet && seatsToSet !== 0) || seatsToSet < 0"
            @click="setExtraSeats"
          >{{ $t('change') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import api from '@/util/api';
import AddMember from './AddMember.vue';
import MembersTable from './MembersTable.vue';
import ImportBatch from './ImportBatch.vue';
import getUserProfile from '@/util/getUserProfile';
import auth from '@/util/auth';
import errorMessage from '@/util/errorMessage';

export default {
  name: 'Members',

  components: {
    AddMember,
    MembersTable,
    ImportBatch,
  },

  data: () => ({
    enterprise: false,
    addExtraSeatsDialog: null,
    seatsToAdd: 1,
    addingSeats: false,
    setExtraSeatsDialog: null,
    seatsToSet: 1,
    settingSeats: false,
    members: [],
    teams: [],
    maxMembers: 0,
    extraMembers: 0,
    membersToAdd: [],
    loadingMembers: false,
    loadingTeams: false,
    panel: -1,
    events: [],
  }),

  computed: {
    membersWithoutSoftDeleted() {
      return this.members.filter((member) => !member.isSoftDeleted);
    },
    canAddMoreMembers() {
      return this.membersWithoutSoftDeleted.length + 1 <= this.maxMembers;
    },
  },

  created() {
    this.loadAll();
  },

  mounted() {
    this.subscribeToSignalREvents();
  },

  beforeDestroy() {
    this.events.forEach((event) => this.$signalR.off(event));
  },

  methods: {
    subscribeToSignalREvents() {
      const customerId = auth.getCustomerId();

      this.events.forEach((event) => this.$signalR.off(event));

      const event = `updatedAccountsCustomerId:${customerId}`;
      this.events.push(event);

      this.$signalR.on(event, () => {
        this.loadMembers();
      });
    },

    loadAll() {
      this.loadCompany();
      this.loadMembers();
    },

    loadCompany(forceRefresh = false) {
      getUserProfile(forceRefresh).then((profile) => {
        this.enterprise = profile.customer.plan === 'enterprise';
        this.maxMembers = profile.customer.maxUsers;
        this.extraMembers = profile.customer.extraUsers;
      });
    },

    async loadMembers() {
      if (this.loadingMembers) {
        return;
      }
      this.loadingMembers = true;

      try {
        const res = await api.getUsersWithSoftDeleted();
        this.members = res.users;
      } catch (error) {
        errorMessage(this.$t('getUsersError'), this.$t('getUsersErrorMsg'), error);
      }

      this.loadingMembers = false;
    },

    updateTeams(teams) {
      this.teams = teams;
    },

    cancelAddMember() {
      this.panel = -1;
    },

    getMemberByEmail(email) {
      email = '' + email;
      return this.members.find((member) => member.email.toLowerCase() === email.toLowerCase());
    },

    getMemberByPhone(phone) {
      return this.members.find((member) => member.mobile === phone);
    },

    // If customer can upgrade
    // addExtraSeats() {
    //   this.addingSeats = true;
    //   api.setExtraUsers(this.extraMembers + this.seatsToAdd)
    //     .then(() => {
    //       this.addingSeats = false;
    //       this.cancelAddExtraSeats();
    //       this.loadCompany(true);
    //     })
    //     .catch((res) => {
    //       this.addingSeats = false;
    //       genericErrorMessage(res);
    //     });
    // },

    // cancelAddExtraSeats() {
    //   this.addExtraSeatsDialog = false;
    //   this.seatsToAdd = 1;
    //   this.addingSeats = false;
    // },

    // setExtraSeats() {
    //   this.settingSeats = true;
    //   api.setExtraUsers(this.seatsToSet)
    //     .then(() => {
    //       this.settingSeats = false;
    //       this.cancelSetExtraSeats();
    //       this.loadCompany(true);
    //     })
    //     .catch((res) => {
    //       this.settingSeats = false;
    //       genericErrorMessage(res);
    //     });
    // },

    // cancelSetExtraSeats() {
    //   this.setExtraSeatsDialog = false;
    //   this.seatsToSet = this.members.length - this.maxMembers < 0 ? 0 : this.members.length - this.maxMembers;
    //   this.settingSeats = false;
    // }
  },
};
</script>

<style lang="scss" scoped>
.cannot-add-more-members-message ::v-deep a {
  color: #fff;
}

.v-expansion-panels .v-expansion-panel {
  &::before {
    box-shadow: none;
  }
}
</style>

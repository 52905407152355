import { on } from '@/lib/ui/dom';
import chardet from 'chardet';

/**
 * Reads a file to string, with automatic encoding detection
 * @param {File} file The file to read
 * @returns {Promise<string>}
 */
export const readToString = (file) =>
  new Promise((resolve) => {
    file.arrayBuffer().then((arrayBuffer) => {
      const buffer = Buffer.from(arrayBuffer);
      const encoding = chardet.detect(buffer);
      const reader = new FileReader();
      on(reader, 'load', () => {
        resolve(reader.result);
      });
      reader.readAsText(file, encoding);
    });
  });

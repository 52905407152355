<template>
  <div>
    <v-form ref="form" v-model="valid">
      <div class="add-member-form">
        <v-text-field
          ref="name"
          v-model="name"
          :rules="nameRules"
          :counter="100"
          :label="$t('name')"
          :disabled="!canAddMoreMembers"
          validate-on-blur
          prepend-icon="fa-user"
        />

        <v-text-field
          ref="email"
          v-model="email"
          :rules="emailRules"
          :counter="100"
          :label="$t('email')"
          :disabled="!canAddMoreMembers"
          type="email"
          validate-on-blur
          prepend-icon="fa-envelope"
        />

        <phone-field
          ref="phone"
          v-model="mobile"
          :rules="phoneRules"
          :label="$t('phone')"
          :disabled="!canAddMoreMembers"
          validate-on-blur
          prepend-icon="fa-mobile"
        />

        <role-selector
          v-model="role"
          :label="$t('role')"
          :disabled="!canAddMoreMembers"
          prepend-icon="fa-user-shield"
        />

        <team-selector
          v-model="userTeams"
          :rules="teamRules"
          :teams="teams"
          :members="members"
          :label="$t('teams')"
          :disabled="!canAddMoreMembers"
          :team-disabled="(team) => team.managed"
          prepend-icon="fa-users"
          :can-create-new-teams="true"
          @teams-loaded="$emit('teams-loaded', $event)"
        />

        <div class="d-flex justify-end align-center">
          <p class="ma-0 my-2 mr-2" :class="canAddMoreMembers ? 'blueDark--text' : 'redDark--text'">
            {{ $t('seatsInUse') }}: {{ members.length }}/{{ maxMembers + extraMembers }}
          </p>
          <v-spacer />
          <v-btn class="mr-2" @click="cancel">{{ $t('cancel') }}</v-btn>
          <v-btn color="primary" :disabled="!valid || submitting" @click="add">{{
            $t('add')
          }}</v-btn>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import { translate as t } from '@/util/i18n';
import PhoneField from '@/components/PhoneField.vue';
import api from '@/util/api';
import { nameRules, emailRules, phoneRules, teamRules } from '@/consts/userRules';
import confirmDialog from '@/components/dialog/confirmDialog';
import errorMessage from '@/util/errorMessage';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import TeamSelector from '@/components/TeamSelector.vue';
import RoleSelector from '@/components/RoleSelector.vue';
import messageDialog from '@/components/dialog/messageDialog';

export default {
  name: 'AddMember',

  components: {
    PhoneField,
    TeamSelector,
    RoleSelector,
  },

  props: {
    teams: {
      type: Array,
      required: true,
    },

    members: {
      type: Array,
      required: false,
      default: () => [],
    },

    getMemberByEmail: {
      type: Function,
      required: false,
      default: () => null,
    },

    getMemberByPhone: {
      type: Function,
      required: false,
      default: () => null,
    },

    maxMembers: {
      type: Number,
      required: false,
      default: 0,
    },

    extraMembers: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  data: (self) => ({
    valid: false,
    submitting: false,

    name: '',
    email: '',
    mobile: null,
    role: 'user',
    userTeams: [],

    nameRules: nameRules(),
    emailRules: emailRules(self.getMemberByEmail),
    phoneRules: phoneRules(self.getMemberByPhone),
    teamRules: teamRules(),
  }),

  computed: {
    canAddMoreMembers() {
      return (
        this.members.length + 1 <= this.maxMembers + this.extraMembers ||
        this.members.length < 1 ||
        this.maxMembers + this.extraMembers < 1
      );
    },
  },

  methods: {
    parsePhone: (phone) => {
      const data = parsePhoneNumberFromString(phone);
      const parsed = Boolean(data);
      return {
        extension: parsed ? data.countryCallingCode : '',
        number: parsed ? data.nationalNumber : '',
        complete: parsed ? data.number : '',
      };
    },

    add() {
      const user = {
        name: this.name,
        email: this.email,
        msisdn: this.mobile.complete,
        role: this.role,
        teamIds: this.userTeams,
      };

      confirmDialog(
        t('addUser'),
        t('addUserMsg', null, {
          name: user.name,
        }),
        t('cancel'),
        t('add'),
      )
        .open()
        .then((confirmed) => {
          if (confirmed) {
            this.submitting = true;

            api
              .addUsers([user])
              .then((res) => {
                if (res.ok.length == 1 && res.fault.length == 0) {
                  this.$emit('memberAdded', user);
                  this.reset();

                  messageDialog(
                    t('memberAdded'),
                    t('memberAddedMsg', null, { name: user.name }),
                    t('ok'),
                    'info',
                  ).open();
                } else {
                  const validationErrors = res.fault[0]?.validationErrors ?? [];
                  if (validationErrors.find((error) => error.type === 3)) {
                    messageDialog(
                      t('addUserExistsError'),
                      t('addUserExistsErrorMsg'),
                      t('ok'),
                      'error',
                    ).open();
                  } else {
                    errorMessage(
                      t('addUserError'),
                      t('addUserErrorMsg'),
                      validationErrors.map((error) => `${error.field} ${error.description}`),
                    );
                  }
                }

                this.submitting = false;
              })
              .catch((res) => {
                errorMessage(t('addUserError'), t('addUserErrorMsg'), res);

                this.submitting = false;
              });
          }
        });
    },

    reset() {
      this.$refs.form.resetValidation();
      this.name = '';
      this.email = '';
      this.mobile = null;
      this.role = 'user';
      this.userTeams = [];
    },

    cancel() {
      this.$emit('cancel');
      this.reset();
    },
  },
};
</script>

<style scoped>
.add-member-form {
  max-width: 700px;
}
</style>

const ROW_DELIMITER = '\n';
const DEFAULT_CELL_DELIMITER = ';';

export default (input) => {
  const rows = input.trim().split(ROW_DELIMITER);
  let cellDelimiter;
  if (rows[0].split(',').length > 2) {
    cellDelimiter = ',';
  } else if (rows[0].split('\t').length > 2) {
    cellDelimiter = '\t';
  } else {
    cellDelimiter = DEFAULT_CELL_DELIMITER;
  }

  return rows
    .map((row) => {
      let cells = row.split(cellDelimiter).map((cell) => {
        cell = cell.trim();
        return cell;
      });

      return cells.join(DEFAULT_CELL_DELIMITER);
    })
    .join(ROW_DELIMITER);
};
